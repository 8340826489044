// Chakra imports
import { Flex } from "@chakra-ui/react";
// Custom components
import Numbers from "sections/numbers";
import AIProjects from "sections/ai-projects";
import DeveloperProducts from "sections/developer-products";
import Hero from "sections/hero";
import CTA from "sections/cta";
import { Helmet } from "react-helmet-async";
export default function Default() {
  return (
    <>
      <Helmet>
        <title>
          Simmmple - Definition of Great Full-Stack Developer Products & AI
          Solutions
        </title>
        <link rel="canonical" href="https://simmmple.com" />

        <meta
          name="keywords"
          content="horizon ui, premium, premium tailwind css, tailwind components, tailwind css components, figma landing kit, figma ui kit, tailwind landing, tailwind template, free Tailwind CSS landing kit, premium Tailwind CSS HTML hero, free Tailwind CSS hero, Tailwind CSS UI Kit, responsive web design, Figma design resources, HTML templates, landing page templates, website development tools, user interface design, CSS framework, Tailwind CSS components, mobile-friendly design, website templates, Figma UI Kit, customizable UI elements, website design toolkit, modern web design, Tailwind CSS framework, SEO-friendly UI Kit, hero section design, landing page sections, premium design resources, responsive UI components, Tailwind CSS themes, HTML and CSS toolkit, user-friendly interface, website builder resources, web development assets, Tailwind CSS customization, UI design elements, Figma templates, HTML landing pages, CSS design kit, web design library, mobile-responsive templates, Tailwind CSS navbar, contact section design, hero section templates, premium HTML templates, Tailwind CSS features, landing page UI elements, responsive website layout, Figma design assets, HTML web development, customizable website sections, premium UI resources, user interface toolkit, mobile-friendly HTML, Tailwind CSS landing sections"
        />
        <meta
          name="description"
          content="Step into the future with Simmmple Labs' popular digital web and AI solutions for developers, startups, and companies. Popular products for developers including integrations & technologies like NextJS, Chakra UI, Tailwind CSS, OpenAI GPT, AI Assistant, Javascript, Typescript, Supabase, Stripe, Figma and AI Products."
        />
        <meta
          name="title"
          content="Simmmple - Definition of Great Full-Stack Developer Products & AI Solutions"
        />
        <meta
          itemprop="title"
          content="Simmmple - Definition of Great Full-Stack Developer Products & AI Solutions"
        />
        <meta
          itemprop="name"
          content="Simmmple - Definition of Great Full-Stack Developer Products & AI Solutions"
        />
        <meta
          itemprop="description"
          content="Step into the future with Simmmple Labs' popular digital web and AI solutions for developers, startups, and companies. Popular products for developers including integrations & technologies like NextJS, Chakra UI, Tailwind CSS, OpenAI GPT, AI Assistant, Javascript, Typescript, Supabase, Stripe, Figma and AI Products."
        />

        <meta
          itemprop="image"
          content="https://i.ibb.co/F55ZNTB/simmmple-meta-image.png"
        />

        <meta name="twitter:card" content="product" />
        <meta name="twitter:site" content="@simmmple_web" />
        <meta
          name="twitter:title"
          content="Simmmple - Definition of Great Full-Stack Developer Products & AI Solutions"
        />

        <meta
          name="twitter:description"
          content="Step into the future with Simmmple Labs' popular digital web and AI solutions for developers, startups, and companies. Popular products for developers including integrations & technologies like NextJS, Chakra UI, Tailwind CSS, OpenAI GPT, AI Assistant, Javascript, Typescript, Supabase, Stripe, Figma and AI Products."
        />
        <meta name="twitter:creator" content="@simmmple_web" />
        <meta
          name="twitter:image"
          content="https://i.ibb.co/F55ZNTB/simmmple-meta-image.png"
        />
        <meta
          property="og:title"
          content="Simmmple - Definition of Great Full-Stack Developer Products & AI Solutions"
        />
        <meta property="og:type" content="product" />
        <meta property="og:url" content="https://simmmple.com" />
        <meta
          property="og:image"
          content="https://i.ibb.co/F55ZNTB/simmmple-meta-image.png"
        />
        <meta
          property="og:description"
          content="Step into the future with Simmmple Labs' popular digital web and AI solutions for developers, startups, and companies. Popular products for developers including integrations & technologies like NextJS, Chakra UI, Tailwind CSS, OpenAI GPT, AI Assistant, Javascript, Typescript, Supabase, Stripe, Figma and AI Products."
        />
        <meta
          property="og:site_name"
          content="Simmmple - Definition of Great Full-Stack Developer Products & AI Solutions"
        />
      </Helmet>
      <Flex direction={{ base: "column" }} overflowX="hidden">
        <Hero />
        <Numbers />
        <AIProjects id="products" />
        <DeveloperProducts />
        <CTA />
      </Flex>
    </>
  );
}
