import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AdminLayout from "layouts"; 
// Chakra imports
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import {   HelmetProvider } from 'react-helmet-async';

ReactDOM.render(
  <ChakraProvider theme={theme}>
	<HelmetProvider>
    <React.StrictMode>
      <BrowserRouter>
        <Switch>
          <Route path={`/`} component={AdminLayout} />
        </Switch> 
      </BrowserRouter>
    </React.StrictMode></HelmetProvider>
  </ChakraProvider>,
  document.getElementById("root")
);
