import { mode } from "@chakra-ui/theme-tools";
const Card = {
  baseStyle: (props) => ({
    p: "20px 20px 30px 20px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
    boxShadow: mode(
      "0px 0.61146px 6.72604px 0px rgba(28, 35, 50, 0.04)",
      "unset"
    )(props),
    borderRadius: "16px",
    minWidth: "0px",
    wordWrap: "break-word",
    bg: mode("#ffffff", "#1C1F39")(props),
    backgroundClip: "border-box",
    borderColor: "#E2E8F0",
    borderWidth: "1px",
  }),
  variants: {
    panel: (props) => ({
      bg: props.colorMode === "dark" ? "gray.700" : "white",
      width: "100%",
      boxShadow: "0px 0.61146px 6.72604px 0px rgba(28, 35, 50, 0.04)",
      borderRadius: "8px",
    }),
  },
};

export const CardComponent = {
  components: {
    Card,
  },
};
