import { mode } from "@chakra-ui/theme-tools";
export const buttonStyles = {
  components: {
    Button: {
      baseStyle: {
        fontSize: "16px",
        borderRadius: "8px",
        transition: ".25s all ease",
        boxSizing: "border-box",
        _focus: {
          boxShadow: "none",
        },
        _active: {
          boxShadow: "none",
        },
      },
      variants: {
        outline: () => ({
          borderRadius: "8px",
        }),
        primary: (props) => ({
          bg: mode("#131417", "#131417")(props),
          color: "white",
          _focus: {
            bg: mode("#131417", "#131417")(props),
          },
          _active: {
            bg: mode("#131417", "#131417")(props),
          },
          _hover: {
            bg: mode("#131417", "#131417")(props),
            boxShadow: "0px 21px 27px -10px rgba(19, 20, 23, 0.48) !important",
          },
        }),
        primaryGradient: (props) => ({
          bg: mode(
            "linear-gradient(0deg, #4A25E1 26.3%, #7B5AFF 86.4%)",
            "linear-gradient(0deg, #4A25E1 26.3%, #7B5AFF 86.4%)"
          )(props),
          color: "white",
          _focus: {
            bg: mode(
              "linear-gradient(0deg, #4A25E1 26.3%, #7B5AFF 86.4%)",
              "linear-gradient(0deg, #4A25E1 26.3%, #7B5AFF 86.4%)"
            )(props),
          },
          _active: {
            bg: mode(
              "linear-gradient(0deg, #4A25E1 26.3%, #7B5AFF 86.4%)",
              "linear-gradient(0deg, #4A25E1 26.3%, #7B5AFF 86.4%)"
            )(props),
          },
          _hover: {
            boxShadow: "0px 21px 27px -10px rgba(96, 60, 255, 0.48) !important",
            bg: mode(
              "linear-gradient(0deg, #4A25E1 26.3%, #7B5AFF 86.4%)",
              "linear-gradient(0deg, #4A25E1 26.3%, #7B5AFF 86.4%)"
            )(props),
          },
        }),
        primaryDefault: (props) => ({
          bg: mode(
            "linear-gradient(0deg, #4A25E1 26.3%, #7B5AFF 86.4%)",
            "linear-gradient(0deg, #4A25E1 26.3%, #7B5AFF 86.4%)"
          )(props),
          color: "white",
          _focus: {
            bg: mode(
              "linear-gradient(0deg, #4A25E1 26.3%, #7B5AFF 86.4%)",
              "linear-gradient(0deg, #4A25E1 26.3%, #7B5AFF 86.4%)"
            )(props),
          },
          _active: {
            bg: mode(
              "linear-gradient(0deg, #4A25E1 26.3%, #7B5AFF 86.4%)",
              "linear-gradient(0deg, #4A25E1 26.3%, #7B5AFF 86.4%)"
            )(props),
          },
          _hover: {
            boxShadow: "0px 21px 27px -10px rgba(96, 60, 255, 0.48) !important",
            bg: mode(
              "linear-gradient(0deg, #4A25E1 26.3%, #7B5AFF 86.4%)",
              "linear-gradient(0deg, #4A25E1 26.3%, #7B5AFF 86.4%)"
            )(props),
          },
        }),
        secondary: (props) => ({
          bg: mode("white", "white")(props),
          color: "#131417",
          _focus: {
            bg: mode("white", "white")(props),
          },
          _active: {
            bg: mode("whiteAlpha.700", "white")(props),
          },
          _hover: {
            // boxShadow:
            //   "0px 21px 27px -10px rgba(255, 255, 255, 0.30) !important",
            bg: mode("whiteAlpha.800", "white")(props),
          },
        }),
        tertiary: (props) => ({
          bg: mode("#131417", "#131417")(props),
          color: "white",
          _focus: {
            bg: mode("#131417", "#131417")(props),
          },
          _active: {
            bg: mode("#131417", "#131417")(props),
          },
          _hover: {
            boxShadow: "0px 21px 27px -10px #A6B1BD !important",
            bg: mode("#131417", "#131417")(props),
          },
        }),
        white: (props) => ({
          bg: mode("white", "white")(props),
          boxShadow: "0px 25px 44px -16px #A6B1BD !important",
          color: "#131417",
          _focus: {
            bg: mode("white", "white")(props),
          },
          _active: {
            bg: mode("white", "white")(props),
            boxShadow: "0px 25px 44px -16px #CBD5E0 !important",
          },
          _hover: {
            boxShadow: "0px 25px 44px -16px #878F98 !important",
            bg: mode("white", "white")(props),
          },
        }),
        brand: (props) => ({
          bg: mode("#633AFF", "#633AFF")(props),
          color: "white",
          _focus: {
            bg: mode(
              "linear-gradient(180deg, #603CFF 0%, #3609FF 100%)",
              "linear-gradient(180deg, #603CFF 0%, #3609FF 100%)"
            )(props),
          },
          _active: {
            bg: mode(
              "linear-gradient(180deg, #603CFF 0%, #3609FF 100%)",
              "linear-gradient(180deg, #603CFF 0%, #3609FF 100%)"
            )(props),
          },
          _hover: {
            opacity: "0.85",
            bg: mode(
              "linear-gradient(180deg, #603CFF 0%, #3609FF 100%)",
              "linear-gradient(180deg, #603CFF 0%, #3609FF 100%)"
            )(props),
          },
        }),
        transparent: (props) => ({
          bg: mode("transparent", "transparent")(props),
          color: "white",
          _focus: {
            bg: mode("transparent", "transparent")(props),
          },
          _active: {
            bg: mode("transparent", "transparent")(props),
          },
          _hover: {
            bg: mode("#EDF2F7", "#EDF2F7")(props),
          },
        }),
        pricing: (props) => ({
          bg: mode("brand.500", "white")(props),
          color: mode("white", "brand.500")(props),
          _focus: {
            bg: mode("brand.500", "white")(props),
          },
          _active: {
            bg: mode("brand.500", "whiteAlpha.900")(props),
          },
          _hover: {
            bg: mode("brand.600", "whiteAlpha.800")(props),
          },
        }),
        silver: (props) => ({
          bg: mode("white", "white")(props),
          borderWidth: "1px",
          borderColor: "gray.200",
          boxShadow:
            "0px 0.61146px 6.72604px 0px rgba(28, 35, 50, 0.04) !important",
          color: mode("#131417", "#131417")(props),
          _focus: {
            bg: mode("white", "white")(props),
          },
          _active: {
            bg: mode("white", "white")(props),
          },
          _hover: {
            boxShadow:
              "0px 0.61146px 6.72604px 0px rgba(28, 35, 50, 0.2) !important",
            bg: mode("white", "white")(props),
          },
        }),
        darkBrand: (props) => ({
          bg: mode("brand.900", "brand.400")(props),
          color: "white",
          _focus: {
            bg: mode("brand.900", "brand.400")(props),
          },
          _active: {
            bg: mode("brand.900", "brand.400")(props),
          },
          _hover: {
            bg: mode("brand.800", "brand.400")(props),
          },
        }),
        lightBrand: (props) => ({
          bg: mode("#F2EFFF", "whiteAlpha.100")(props),
          color: mode("brand.500", "white")(props),
          _focus: {
            bg: mode("#F2EFFF", "whiteAlpha.100")(props),
          },
          _active: {
            bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          },
          _hover: {
            bg: mode("secondaryGray.400", "whiteAlpha.200")(props),
          },
        }),
        light: (props) => ({
          bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          color: mode("#131417", "white")(props),
          _focus: {
            bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          },
          _active: {
            bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          },
          _hover: {
            bg: mode("secondaryGray.400", "whiteAlpha.200")(props),
          },
        }),
        action: (props) => ({
          fontWeight: "500",
          borderRadius: "50px",
          bg: mode("secondaryGray.300", "brand.400")(props),
          color: mode("brand.500", "white")(props),
          _focus: {
            bg: mode("secondaryGray.300", "brand.400")(props),
          },
          _active: { bg: mode("secondaryGray.300", "brand.400")(props) },
          _hover: {
            bg: mode("secondaryGray.200", "brand.400")(props),
          },
        }),
        setup: (props) => ({
          fontWeight: "500",
          borderRadius: "50px",
          bg: mode("transparent", "brand.400")(props),
          border: mode("1px solid", "0px solid")(props),
          borderColor: mode("secondaryGray.400", "transparent")(props),
          color: mode("#131417", "white")(props),
          _focus: {
            bg: mode("transparent", "brand.400")(props),
          },
          _active: { bg: mode("transparent", "brand.400")(props) },
          _hover: {
            bg: mode("secondaryGray.100", "brand.400")(props),
          },
        }),
      },
    },
  },
};
