import React from "react";
// Chakra imports
import { Flex, Text, useColorModeValue, SimpleGrid } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
// Assets
import horizonuilogo from "assets/img/developer-products/horizonui-logo.png";
import horizonuiimg from "assets/img/developer-products/horizonui-img.png";
import horizonuiproimg from "assets/img/developer-products/horizonuipro-img.png";
import aitemplatelogo from "assets/img/developer-products/ai-template.png";
import aitemplateimg from "assets/img/developer-products/aitemplate-img.png";
import boilerplatelogo from "assets/img/developer-products/boilerplate.png";
import boilerplateimg from "assets/img/developer-products/boilerplate-img.png";
import corporatelogo from "assets/img/developer-products/corporate-logo.png";
import corporateimg from "assets/img/developer-products/corporate-img.png";
import landingkitlogo from "assets/img/developer-products/landingkit-logo.png";
import landingkitimg from "assets/img/developer-products/landingkit-img.png";
import generatorlogo from "assets/img/developer-products/generator-logo.png";
import generatorimg from "assets/img/developer-products/generator-img.png";
import aiboilerplatelogo from "assets/img/developer-products/aiboilerplate-logo.png";
import aiboilerplateimg from "assets/img/developer-products/aiboilerplate-img.png";
import puritylogo from "assets/img/developer-products/purity-logo.png";
import purityimg from "assets/img/developer-products/purity-img.png";
import visionlogo from "assets/img/developer-products/vision-logo.png";
import visionimg from "assets/img/developer-products/vision-img.png";
import muivisionlogo from "assets/img/developer-products/muivision-logo.png";
import muivisionimg from "assets/img/developer-products/muivision-img.png";
import argonlogo from "assets/img/developer-products/argon-logo.png";
import argonimg from "assets/img/developer-products/argon-img.png";
// Custom components
import InnerContent from "layouts/innerContent";
import ToolCard from "./components/ToolCard";

export default function AIProjects() {
  // Chakra Color Mode
  const textColor = useColorModeValue("#131417", "white");
  // const brandColor = useColorModeValue('brand.500', 'white');
  return (
    <Flex
      direction={{ base: "column" }}
      w="100%"
      maxW="100%"
      overflow="hidden"
      justifyContent="center"
      alignItems="center"
      position="relative"
      mt={{
        base: "60px",
        md: "100px",
        lg: "120px",
        xl: "120px",
        "2xl": "120px",
        "3xl": "120px",
      }}
    >
      <Flex
        maxW="1500px"
        w="96vw"
        bgColor="#FAFAFA"
        pt={{
          base: "60px",
          md: "100px",
          lg: "120px",
          xl: "120px",
          "2xl": "120px",
          "3xl": "120px",
        }}
        pb={{
          base: "60px",
          md: "100px",
          lg: "120px",
          xl: "120px",
          "2xl": "120px",
          "3xl": "120px",
        }}
        bgPosition="top"
        borderRadius={{ base: "20px", md: "50px" }}
      >
        <InnerContent zIndex="10" w="100%" justifyContent="flex-start">
          <Flex
            maxW="100%"
            direction="column"
            justify="center"
            alignItems="center"
            width="stretch"
            px={{ base: "20px", md: "20px", xl: "0px" }}
          >
            <Flex
              direction="column"
              mx="auto"
              justify="center"
              alignItems="center"
              mb="40px"
              maxW={{ base: "100%", lg: "100%", xl: "70%" }}
              textAlign="center"
            >
              <Text
                as="h2"
                color={textColor}
                fontWeight="600"
                fontSize={{ base: "30px", md: "42px", lg: "58px", xl: "58px" }}
                lineHeight={{
                  base: "42px",
                  md: "54px",
                  lg: "68px",
                  xl: "68px",
                }}
                mb={{ base: "14px", lg: "30px" }}
                w={{
                  base: "100%",
                  md: "60%",
                  lg: "66%",
                  xl: "76%",
                  "2xl": "76%",
                  "3xl": "76%",
                }}
              >
                Products & Solutions for Developers
              </Text>
              <Text
                color="gray.600"
                fontSize={{ base: "sm", md: "md", xl: "lg" }}
                lineHeight={{ base: "24px", md: "30px" }}
                fontWeight="500"
                letterSpacing="0px"
                w={{
                  base: "90%",
                  md: "66%",
                  lg: "50%",
                  xl: "64%",
                  "2xl": "64%",
                  "3xl": "64%",
                }}
                mb={{ base: "0px", xl: "40px" }}
              >
                List of some of the biggest Front-end & Back-end solutions for
                web developers, businesses, and startups.
              </Text>
            </Flex>
          </Flex>
          <Flex
            gap="20px"
            direction="column"
            w="100%"
            px={{ base: "20px", xl: "0px" }}
          >
            <SimpleGrid
              w="100%"
              columns={{ base: "1", md: "2", xl: "2" }}
              gap="20px"
            >
              <ToolCard
                altImage="Horizon UI Core (20+ Tech stacks)"
                image={horizonuiimg}
                logo={horizonuilogo}
                title="Horizon UI Core (20+ Tech stacks)"
                description="Trendiest Open-Source Admin Template for React"
                link="https://horizon-ui.com?ref=simmmple.com"
              />
              <ToolCard
                altImage="Horizon UI PRO"
                image={horizonuiproimg}
                logo={horizonuilogo}
                title="Horizon UI PRO"
                description="Premium Admin Template for React"
                link="https://horizon-ui.com/pro?ref=simmmple.com"
              />
              <ToolCard
                altImage="Horizon AI Template"
                image={aitemplateimg}
                logo={aitemplatelogo}
                title="Horizon AI Template"
                description="World’s first ChatGPT Admin Template & Kit"
                link="https://horizon-ui.com/ai-template?ref=simmmple.com"
              />
              <ToolCard
                altImage="Horizon UI Boilerplate"
                image={boilerplateimg}
                logo={boilerplatelogo}
                title="Horizon UI Boilerplate"
                description="NextJS Boilerplate for launching SaaS startups 10X faster"
                link="https://horizon-ui.com/boilerplate?ref=simmmple.com"
              />
              <ToolCard
                altImage="Horizon UI Corporate"
                image={corporateimg}
                logo={corporatelogo}
                title="Horizon UI Corporate"
                description="Corporate & Business version of Horizon UI PRO"
                link="https://horizon-ui.com/pro-corporate?ref=simmmple.com"
              />
              <ToolCard
                altImage="Horizon UI Landing Kit"
                image={landingkitimg}
                logo={landingkitlogo}
                title="Horizon UI Landing Kit"
                description="Figma & Tailwind CSS Landing Sections Kit"
                link="https://horizon-ui.com/landing-kit?ref=simmmple.com"
              />
              <ToolCard
                altImage="Horizon UI App Generator"
                image={generatorimg}
                logo={generatorlogo}
                title="Horizon UI App Generator"
                description="ReactJS Web App Generator"
                link="https://horizon-ui.com/app-generator?ref=simmmple.com"
              />
              <ToolCard
                altImage="Horizon AI Boilerplate"
                image={aiboilerplateimg}
                logo={aiboilerplatelogo}
                title="Horizon AI Boilerplate"
                description="ShadCN UI NextJS GPT AI Boilerplate"
                link="https://horizon-ui.com/boilerplate?ref=simmmple.com"
              />
              <ToolCard
                altImage="Purity UI Dashboard"
                image={purityimg}
                logo={puritylogo}
                title="Purity UI Dashboard"
                description="World’s first Chakra UI Admin Template"
                link="https://www.creative-tim.com/product/purity-ui-dashboard?ref=simmmple.com"
              />
              <ToolCard
                altImage="Vision UI Dashboard"
                image={visionimg}
                logo={visionlogo}
                title="Vision UI Dashboard"
                description="Vision UI & Chakra UI Admin Template"
                link="https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra?ref=simmmple.com"
              />
              <ToolCard
                altImage="Vision UI MUI Dashboard"
                image={muivisionimg}
                logo={muivisionlogo}
                title="Vision UI MUI Dashboard"
                description="Vision UI & Material UI Admin Template"
                link="https://www.creative-tim.com/product/vision-ui-dashboard-pro-react?ref=simmmple.com"
              />
              <ToolCard
                altImage="Argon Chakra UI"
                image={argonimg}
                logo={argonlogo}
                title="Argon Chakra UI"
                description="Argon UI & Chakra UI Admin Template"
                link="https://www.creative-tim.com/product/argon-dashboard-chakra-pro?ref=simmmple.com"
              />
            </SimpleGrid>
            <Card
              py={{ base: "30px", xl: "60px" }}
              zIndex="10"
              transition="0.2s linear"
              direction="column"
              p="12px"
              maxW="100%"
            >
              <Flex
                w="100%"
                h={{ base: "100px", md: "100%" }}
                justifyContent="center"
                alignItems="center"
              >
                <Text
                  as="h3"
                  mt="2px"
                  mb={{ base: "0px", md: "4px" }}
                  color={textColor}
                  fontSize={{ base: "sm", lg: "md", xl: "lg" }}
                  fontWeight={"600"}
                  textAlign="center"
                  lineHeight={{ base: "140%", md: "160%" }}
                  w={{ base: "100%", md: "50%", xl: "40%" }}
                  maxW="100%"
                  letterSpacing="0px"
                >
                  + Multiple Free versions, Documentation pages, Figma versions
                  & Under Development Products
                </Text>
              </Flex>
            </Card>
          </Flex>
        </InnerContent>
      </Flex>
    </Flex>
  );
}
