/*eslint-disable*/
import {
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import horizonuilogomain from "assets/img/horizonuilogomain.png";
import { HSeparator } from "components/separator/Separator";
export default function Footer() {
  const textColorSecondary = useColorModeValue("gray.600", "white");
  const textFooter = useColorModeValue("#131417", "white");
  return (
    <Flex
      zIndex="3"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      pb="30px"
      pt={{ base: "60px", md: "100px", lg: "100px", xl: "140px" }}
    >
      <Link alignItems="center" href="/">
        <Image w="179px" src={horizonuilogomain} />
      </Link>
      <HSeparator my="30px" maxW="1170px" mx="auto" bg="gray.200" />
      <Flex direction="column" mx="auto" justifyContent="center">
        <Text
          me="4px"
          letterSpacing="0px"
          fontSize="sm"
          fontWeight="500"
          textAlign="center"
          textColor={textColorSecondary}
        >
          Simmmple © 2024 Copyright. All rights reserved.
        </Text>
        <Text
          me="4px"
          letterSpacing="0px"
          fontSize="sm"
          textAlign="center"
          fontWeight="500"
          textColor={textColorSecondary}
        >
          Proudly made in 🇷🇴 Bucharest, Romania
        </Text>
      </Flex>
    </Flex>
  );
}
