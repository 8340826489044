/*!
  _   _  ___  ____  ___ ________  _   _      _    ___   _____ _____ __  __ ____  _        _  _____ _____ 
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | |    / \  |_ _| |_   _| ____|  \/  |  _ \| |      / \|_   _| ____|
 | |_| | | | | |_) || |  / / | | |  \| |   / _ \  | |    | | |  _| | |\/| | |_) | |     / _ \ | | |  _|  
 |  _  | |_| |  _ < | | / /| |_| | |\  |  / ___ \ | |    | | | |___| |  | |  __/| |___ / ___ \| | | |___ 
 |_| |_|\___/|_| \_\___/____\___/|_| \_| /_/   \_\___|   |_| |_____|_|  |_|_|   |_____/_/   \_\_| |_____|                                                                                                
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon AI Template - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/ai-template/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Button,
  Flex,
  Link,
  Icon,
  Avatar,
  AvatarGroup,
  Text,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets

// Custom components
import InnerContent from "layouts/innerContent";
import { IoIosStar } from "react-icons/io";
import left from "assets/img/hero/left-image.png";
import right from "assets/img/hero/right-image.png";
import line from "assets/img/hero/line.png";
import avatar1 from "assets/img/avatars/alex-avatar-pro-1.png";
import avatar2 from "assets/img/avatars/paul-avatar-pro-2.png";
import avatar3 from "assets/img/avatars/elisa-avatar-pro-3.png";
import avatar4 from "assets/img/avatars/rares-avatar-pro-4.png";
import avatar5 from "assets/img/avatars/lee-avatar-pro-5.png";
import avatar6 from "assets/img/avatars/adi-avatar-pro-6.png";
import React from "react";

export default function Hero() {
  // Chakra Color Mode
  const textColor = useColorModeValue("#131417", "white");
  return (
    <Flex
      w="100%"
      direction="column"
      pt={{
        base: "100px",
        md: "100px",
        lg: "120px",
        xl: "120px",
        "2xl": "120px",
        "3xl": "120px",
      }}
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      <Flex
        pt={{
          base: "60px",
          md: "100px",
          lg: "100px",
          xl: "100px",
          "2xl": "100px",
          "3xl": "120px",
        }}
        pb={{
          base: "60px",
          md: "100px",
          lg: "80px",
          xl: "100px",
          "2xl": "100px",
          "3xl": "120px",
        }}
        maxW="1500px"
        w="96vw"
        bgColor="#FAFAFA"
        bgPosition="top"
        borderRadius={{ base: "20px", md: "50px" }}
      >
        <InnerContent
          w="1170px"
          maxW="100%"
          px={{ base: "20px", md: "40px", xl: "0px" }}
          zIndex="2"
        >
          <Flex
            maxW="100%"
            direction="column"
            width="stretch"
            justify="center"
            align="center"
          >
            <Flex
              direction="column"
              mx="auto"
              px={{ base: "0px", md: "40px", xl: "0px" }}
              maxW={{ base: "100%", lg: "100%", xl: "74%", "2xl": "78%" }}
              textAlign="center"
            >
              <Image
                src={left}
                w="60px"
                h="60px"
                position="absolute"
                left={{
                  base: "44px",
                  md: "170px",
                  lg: "230px",
                  xl: "440px",
                  "2xl": "580px",
                }}
                top={{
                  base: "140px",
                  md: "180px",
                  lg: "200px",
                  xl: "200px",
                  "2xl": "200px",
                }}
              />
              <Text
                as="h1"
                color={textColor}
                fontWeight="500"
                fontSize={{ base: "28px", md: "42px", lg: "58px", xl: "58px" }}
                lineHeight={{
                  base: "38px",
                  md: "58px",
                  lg: "68px",
                  xl: "68px",
                }}
                px={{
                  base: "0px",
                  md: "30px",
                  lg: "20px",
                  xl: "30px",
                  "2xl": "60px",
                }}
                mb={{ base: "24px", md: "30px" }}
              >
                Definition of Great
                <Text
                  as="h1"
                  fontStyle="italic"
                  fontWeight="800"
                  fontFamily="Lora, serif"
                  position={"relative"}
                  bgClip="text"
                  color={textColor}
                >
                  Full-Stack & AI Solutions
                </Text>
              </Text>
              <Image
                src={right}
                w="60px"
                h="60px"
                position="absolute"
                right={{
                  base: "6px",
                  md: "114px",
                  lg: "150px",
                  xl: "360px",
                  "2xl": "504px",
                }}
                bottom={{
                  base: "460px",
                  md: "410px",
                  lg: "390px",
                  xl: "410px",
                  "2xl": "410px",
                }}
              />
              <Text
                mb="40px"
                color="gray.600"
                alignSelf="center"
                fontSize={{ base: "md", md: "md", xl: "lg" }}
                lineHeight={{ base: "28px", md: "30px" }}
                letterSpacing="0px"
                fontWeight={"500"}
                w={{
                  base: "93%",
                  md: "90%",
                  lg: "62%",
                  xl: "70%",
                  "2xl": "60%",
                }}
              >
                Step into the future with Simmmple Labs' popular web and AI
                solutions for developers, startups, and companies.
              </Text>
              <Flex
                align="center"
                direction={{ base: "column", md: "row" }}
                justifyContent={{ base: "center", lg: "center" }}
              >
                <Link href="#products">
                  <Button
                    variant="primary"
                    py="20px"
                    px="16px"
                    fontSize="md"
                    borderRadius="45px"
                    w={{ base: "300px", lg: "320px" }}
                    h="70px"
                  >
                    Discover All Products
                  </Button>
                </Link>
              </Flex>
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  gap="10px"
                  mt="50px"
                  direction={{ base: "column", md: "row" }}
                >
                  <AvatarGroup spacing="-26px" size="md">
                    <Avatar
                      showBorder="true"
                      bg="#FAFAFA"
                      width="60px"
                      height="60px"
                      borderWidth="3px"
                      borderColor="#FAFAFA"
                      src={avatar1}
                    />
                    <Avatar
                      showBorder="true"
                      bg="#FAFAFA"
                      width="60px"
                      height="60px"
                      borderWidth="3px"
                      borderColor="#FAFAFA"
                      src={avatar2}
                    />
                    <Avatar
                      showBorder="true"
                      bg="#FAFAFA"
                      width="60px"
                      height="60px"
                      borderWidth="3px"
                      borderColor="#FAFAFA"
                      src={avatar3}
                    />
                    <Avatar
                      showBorder="true"
                      bg="#FAFAFA"
                      width="60px"
                      height="60px"
                      borderWidth="3px"
                      borderColor="#FAFAFA"
                      src={avatar4}
                    />
                    <Avatar
                      showBorder="true"
                      bg="#FAFAFA"
                      width="60px"
                      height="60px"
                      borderWidth="3px"
                      borderColor="#FAFAFA"
                      src={avatar5}
                    />
                    <Avatar
                      showBorder="true"
                      bg="#FAFAFA"
                      width="60px"
                      height="60px"
                      borderWidth="3px"
                      borderColor="#FAFAFA"
                      src={avatar6}
                    />
                  </AvatarGroup>
                  <Flex
                    direction={"column"}
                    justify={{ base: "center", md: "start" }}
                  >
                    <Flex
                      justify={{ base: "center", md: "start" }}
                      alignItems="center"
                    >
                      <Icon
                        as={IoIosStar}
                        w="16px"
                        h="16px"
                        color="orange.300"
                      />
                      <Icon
                        as={IoIosStar}
                        w="16px"
                        h="16px"
                        color="orange.300"
                      />
                      <Icon
                        as={IoIosStar}
                        w="16px"
                        h="16px"
                        color="orange.300"
                      />
                      <Icon
                        as={IoIosStar}
                        w="16px"
                        h="16px"
                        color="orange.300"
                      />
                      <Icon
                        as={IoIosStar}
                        w="16px"
                        h="16px"
                        color="orange.300"
                      />
                    </Flex>
                    <Text
                      mt="4px"
                      color="gray.600"
                      textAlign={{ base: "center", md: "start" }}
                      fontWeight="500"
                      letterSpacing="0px"
                      fontSize="sm"
                    >
                      Solutions used by over
                      <Text fontWeight="700" color="#131417">
                        200,000+ users worldwide
                      </Text>
                    </Text>
                  </Flex>
                </Flex>
                <Image
                  src={line}
                  w="113px"
                  h="13px"
                  ms={{
                    base: "-70px",
                    md: "166px",
                    lg: "166px",
                    xl: "166px",
                    "2xl": "166px",
                  }}
                  mt="4px"
                />
              </Flex>
            </Flex>
          </Flex>
        </InnerContent>
      </Flex>
    </Flex>
  );
}
