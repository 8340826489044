/* eslint-disable */
// Chakra Imports
import { Box, Image, Flex, Link, useColorModeValue } from "@chakra-ui/react";
import horizonuilogomain from "assets/img/horizonuilogomain.png";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
export default function AdminNavbar(props) {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  });

  const { secondary } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let navbarPosition = "fixed";
  let navbarFilter = "none";
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  let navbarBg = useColorModeValue("whiteAlpha.700", "rgba(11,20,55,0.5)");
  let navbarBorder = "transparent";
  let paddingX = "15px";

  let secondaryMargin = "90px";
  let gap = "0px";
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  if (scrolled) secondaryMargin = "0px";
  else secondaryMargin = "55px";
  if (!scrolled) navbarBackdrop = "unset";
  if (!scrolled) navbarBg = "transparent";
  return (
    <Box
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition="center"
      backgroundSize="cover"
      zIndex="200"
      borderRadius="10px"
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: "center" }}
      display={secondary ? "block" : "flex"}
      minH="78px"
      justifyContent={{ xl: "center" }}
      lineHeight="25.6px"
      mx="auto"
      py="8px"
      left="50%"
      transform="translate(-50%,0px)"
      px={{
        sm: paddingX,
        md: "10px",
        lg: "12px",
      }}
      ps={{
        xl: "12px",
      }}
      top={{ base: "12px", md: "16px", xl: "18px" }}
      w={{
        base: "calc(100vw - 6%)",
        md: "calc(100vw - 8%)",
        lg: "calc(100vw - 6%)",
        xl: "calc(100vw - 250px)",
        "2xl": "1200px",
      }}
    >
      <Flex
        w="100%"
        flexDirection={{
          sm: "row",
          md: "row",
        }}
        alignItems="center"
        justify="center"
        mb={gap}
      >
        <Link
          display={"flex"}
          alignItems="center"
          justifyContent={"center"}
          href="/"
        >
          <Image me="10px" w="179px" src={horizonuilogomain} />
        </Link>
      </Flex>
    </Box>
  );
}

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
