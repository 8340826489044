import React from "react";
// Chakra imports
import { Flex, Text, useColorModeValue, SimpleGrid } from "@chakra-ui/react";
import Card from "components/card/Card";
// Custom components
import InnerContent from "layouts/innerContent";

export default function Numbers() {
  // Chakra Color Mode
  const textColor = useColorModeValue("#131417", "white");
  return (
    <Flex
      zIndex="2"
      w="100%"
      direction={{ base: "column" }}
      bgSize="cover"
      position="relative"
      pt={{ base: "80px", md: "100px", xl: "120px" }}
      pb={{ base: "80px", md: "100px", xl: "120px" }}
    >
      <InnerContent
        px={{ base: "20px", md: "20px", xl: "0px" }}
        w="100%"
        maxW="100%"
      >
        <SimpleGrid
          columns={{ base: "1", md: "2", lg: "4", xl: "4" }}
          spacing="20px"
          w={{ base: "100%", lg: "100%", xl: "1170px" }}
        >
          <Card
            w="100%"
            alignItems="center"
            justifyContent="center"
            borderRadius="16px"
            py="60px"
            px="20px"
          >
            <Text
              as="h2"
              mx="auto"
              fontSize="48px"
              fontWeight="600"
              textAlign="center"
              letterSpacing="0px"
              color={textColor}
            >
              60+
            </Text>
            <Text
              ms="2px"
              textAlign="center"
              fontSize="lg"
              fontWeight="500"
              color="gray.600"
            >
              Released Products
            </Text>
          </Card>
          <Card
            w="100%"
            alignItems="center"
            justifyContent="center"
            borderRadius="16px"
            py="60px"
            px="20px"
          >
            <Text
              as="h2"
              mx="auto"
              fontSize="48px"
              fontWeight="600"
              textAlign="center"
              letterSpacing="0px"
              color={textColor}
            >
              5,300+
            </Text>
            <Text
              ms="2px"
              textAlign="center"
              fontSize="lg"
              fontWeight="500"
              color="gray.600"
            >
              Total Github Stars
            </Text>
          </Card>
          <Card
            w="100%"
            alignItems="center"
            justifyContent="center"
            borderRadius="16px"
            py="60px"
            px="10px"
          >
            <Text
              as="h2"
              mx="auto"
              fontSize="48px"
              fontWeight="600"
              textAlign="center"
              letterSpacing="0px"
              color={textColor}
            >
              140,000+
            </Text>
            <Text
              ms="2px"
              textAlign="center"
              fontSize="lg"
              fontWeight="500"
              color="gray.600"
            >
              Figma Duplicates
            </Text>
          </Card>
          <Card
            w="100%"
            alignItems="center"
            justifyContent="center"
            borderRadius="16px"
            py="60px"
            px="20px"
          >
            <Text
              as="h2"
              mx="auto"
              fontSize="48px"
              fontWeight="600"
              textAlign="center"
              letterSpacing="0px"
              color={textColor}
            >
              2019
            </Text>
            <Text
              ms="2px"
              textAlign="center"
              fontSize="lg"
              fontWeight="500"
              color="gray.600"
            >
              Year Founded
            </Text>
          </Card>
        </SimpleGrid>
      </InnerContent>
    </Flex>
  );
}
