import React from "react";
// Chakra imports
import { Flex, Text, useColorModeValue, SimpleGrid } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
// Assets
import essaybuilderlogo from "assets/img/ai-projects/essay-builder.png";
import textenhancerlogo from "assets/img/ai-projects/text-enhancer.png";
import textsummarizerlogo from "assets/img/ai-projects/text-summarizer.png";
import synapsologo from "assets/img/ai-projects/synapso.png";
import eduwizlogo from "assets/img/ai-projects/eduwiz.png";
import zodiaclogo from "assets/img/ai-projects/zodiac-chat.png";
import texthumanizerlogo from "assets/img/ai-projects/text-humanizer.png";
import essayimg from "assets/img/ai-projects/essay-img.png";
import enhancerimg from "assets/img/ai-projects/enhancer-img.png";
import humanizerimg from "assets/img/ai-projects/humanizer-img.png";
import synapsoimg from "assets/img/ai-projects/synapso-img.png";
import eduwizimg from "assets/img/ai-projects/eduwiz-img.png";
import summarizerimg from "assets/img/ai-projects/summarizer-img.png";
import zodiacimg from "assets/img/ai-projects/zodiac-img.png";
import ttsimg from "assets/img/ai-projects/tts-img.png";
import ttslogo from "assets/img/ai-projects/tts-generator.png";
import aitemplateimg from "assets/img/ai-projects/aitemplate-img.png";
import aitemplatelogo from "assets/img/ai-projects/ai-template.png";
import boilerplateimg from "assets/img/ai-projects/boilerplate-img.png";
import boilerplatelogo from "assets/img/ai-projects/boilerplate.png";
import aiboilerplateimg from "assets/img/ai-projects/aiboilerplate-img.png";
import aiboilerplatelogo from "assets/img/ai-projects/aiboilerplate.png";
import factsgeneratorimg from "assets/img/ai-projects/facts-img.png";
import factsgeneratorlogo from "assets/img/ai-projects/facts.png";
// Custom components
import InnerContent from "layouts/innerContent";
import ToolCard from "./components/ToolCard";

export default function AIProjects() {
	// Chakra Color Mode
	const textColor = useColorModeValue("#131417", "white");
	// const brandColor = useColorModeValue('brand.500', 'white');
	return (
		<Flex
			direction={{ base: "column" }}
			w='100%'
			maxW='100%'
			overflow='hidden'
			justifyContent='center'
			alignItems='center'
			position='relative'
			id='products'>
			<Flex
				maxW='1500px'
				w='96vw'
				bgColor='#FAFAFA'
				pt={{
					base: "60px",
					md: "100px",
					lg: "120px",
					xl: "120px",
					"2xl": "120px",
					"3xl": "120px",
				}}
				pb={{
					base: "60px",
					md: "100px",
					lg: "120px",
					xl: "120px",
					"2xl": "120px",
					"3xl": "120px",
				}}
				bgPosition='top'
				borderRadius={{ base: "20px", md: "50px" }}>
				<InnerContent zIndex='10' w='100%' justifyContent='flex-start'>
					<Flex
						maxW='100%'
						direction='column'
						justify='center'
						alignItems='center'
						width='stretch'
						px={{ base: "20px", md: "20px", xl: "0px" }}>
						<Flex
							direction='column'
							mx='auto'
							justify='center'
							alignItems='center'
							mb='40px'
							maxW={{ base: "100%", lg: "100%", xl: "70%" }}
							textAlign='center'>
							<Text
								as='h2'
								color={textColor}
								fontWeight='600'
								fontSize={{ base: "30px", md: "42px", lg: "58px", xl: "58px" }}
								lineHeight={{
									base: "42px",
									md: "58px",
									lg: "68px",
									xl: "68px",
								}}
								mb={{ base: "14px", lg: "30px" }}>
								AI Products & Solutions
							</Text>
							<Text
								color='gray.600'
								fontSize={{ base: "sm", md: "md", xl: "lg" }}
								lineHeight={{ base: "24px", md: "30px" }}
								fontWeight='500'
								letterSpacing='0px'
								w={{
									base: "100%",
									md: "80%",
									lg: "60%",
									xl: "76%",
									"2xl": "76%",
									"3xl": "76%",
								}}
								mb={{ base: "0px", xl: "40px" }}>
								List of some of the biggest AI solutions for students, writers,
								developers, marketers, businesses, hobbyists, and more.
							</Text>
						</Flex>
					</Flex>
					<SimpleGrid
						w='100%'
						columns={{ base: "1", md: "2", xl: "3" }}
						gap='20px'
						px={{ base: "20px", xl: "0px" }}>
						<ToolCard
							altImage='Essay Builder AI'
							image={essayimg}
							logo={essaybuilderlogo}
							title='Essay Builder AI'
							description='Free Essay writer & generator'
							link='https://essay-builder.ai?ref=simmmple.com'
						/>
						<ToolCard
							altImage='TTS Generator AI'
							image={ttsimg}
							logo={ttslogo}
							title='TTS Generator AI'
							description='Free Text to Speech AI Tool'
							link='https://tts-generator.com?ref=simmmple.com'
						/>
						<ToolCard
							altImage='EduWiz.AI'
							image={eduwizimg}
							logo={eduwizlogo}
							title='EduWiz.AI'
							description='Free AI Tool for Student'
							link='https://eduwiz.ai?ref=simmmple.com'
						/>
						<ToolCard
							altImage='Horizon AI Template'
							image={aitemplateimg}
							logo={aitemplatelogo}
							title='Horizon AI Template'
							description="World's first GPT AI Template"
							link='https://horizon-ui.com/ai-template?ref=simmmple.com'
						/>
						<ToolCard
							altImage='Text Enhancer AI'
							image={enhancerimg}
							logo={textenhancerlogo}
							title='Text Enhancer AI'
							description='Free Text Rephrase AI Tool'
							link='https://text-enhancer.com?ref=simmmple.com'
						/>
						<ToolCard
							altImage='Horizon UI Boilerplate'
							image={boilerplateimg}
							logo={boilerplatelogo}
							title='Horizon UI Boilerplate'
							description='NextJS GPT Web Template'
							link='https://horizon-ui.com/boilerplate?ref=simmmple.com'
						/>
						<ToolCard
							altImage='Text Summarizer AI'
							image={summarizerimg}
							logo={textsummarizerlogo}
							title='Text Summarizer AI'
							description='Free Text Summarizer AI Tool'
							link='https://summarizer-ai.com?ref=simmmple.com'
						/>
						<ToolCard
							altImage='Zodiac Chat AI'
							image={zodiacimg}
							logo={zodiaclogo}
							title='Zodiac Chat AI'
							description='Free Zodiac & Horoscope Tool'
							link='https://zodiac-chat.com?ref=simmmple.com'
						/>
						<ToolCard
							altImage='Text Humanizer AI'
							image={humanizerimg}
							logo={texthumanizerlogo}
							title='Text Humanizer AI'
							description='Free AI Text Humanizer Tool'
							link='https://text-humanizer.com?ref=simmmple.comr'
						/>
						<ToolCard
							altImage='Fact Generator AI'
							image={factsgeneratorimg}
							logo={factsgeneratorlogo}
							title='Fact Generator AI'
							description='Free Facts Generator AI Tool'
							link='https://facts-generator.com?ref=simmmple.comr'
						/>
						<ToolCard
							altImage='Horizon AI Boilerplate'
							image={aiboilerplateimg}
							logo={aiboilerplatelogo}
							title='Horizon AI Boilerplate'
							description='ShadCN NextJS GPT Template'
							link='https://horizon-ui.com/boilerplate?ref=simmmple.com'
						/>
						<ToolCard
							altImage='Synapso AI'
							image={synapsoimg}
							logo={synapsologo}
							title='Synapso AI'
							description='Free Marketing Content AI Tool'
							link='https://synapso.ai?ref=simmmple.com'
						/>
						{/* <Card
							zIndex='10'
							transition='0.2s linear'
							direction='column'
							p='12px'
							maxW='100%'>
							<Flex
								w='100%'
								h={{ base: "100px", md: "100%" }}
								justifyContent='center'
								alignItems='center'>
								<Text
									as='h3'
									mt='2px'
									mb={{ base: "0px", md: "4px" }}
									color={textColor}
									fontSize={{ base: "md", lg: "md", xl: "lg" }}
									fontWeight={"600"}
									textAlign='center'
									lineHeight={{ base: "140%", md: "160%" }}
									w={{ base: "100%", md: "70%" }}
									maxW='100%'
									letterSpacing='0px'>
									+ Multiple new AI tools under development
								</Text>
							</Flex>
						</Card> */}
					</SimpleGrid>
				</InnerContent>
			</Flex>
		</Flex>
	);
}
